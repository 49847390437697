import React from 'react';
import { Text, Button, Stack, Group, useMantineTheme } from '@mantine/core';
import { FaExclamationCircle } from 'react-icons/fa';
import { ContextModalProps } from '@mantine/modals';
import { PaymentErrorProps } from 'types/modals';
import TitleWithClose from '../components/TitleWithClose';

const PaymentErrorModal = ({ id, context, innerProps }: ContextModalProps<PaymentErrorProps>) => {
  const { openPaymentMethod } = innerProps;
  const theme = useMantineTheme();

  const handleOpenPaymentMethod = () => {
    context.closeModal(id);
    openPaymentMethod();
  }
  
  return (
    <>
      <TitleWithClose id={id} title={'Payment Error'} />
      <Stack align="center" spacing="md">
        <FaExclamationCircle size={48} color={theme.colors.red[6]} />
        <Text color={theme.colors.red[6]} size="lg" align="center">
          There was an error processing your payment.
        </Text>
        <Text align="center">
          Please add a different payment method and try again.
        </Text>
        <Group >
          <Button 
            variant="outline"
            onClick={handleOpenPaymentMethod} 
            fullWidth
          >
            Change Payment Method
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default PaymentErrorModal;