import React, { useEffect, useState } from 'react';
import TitleWithClose from '../components/TitleWithClose';
import { ContextModalProps } from '@mantine/modals';
import { Text, Group, Checkbox } from '@mantine/core';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { HandshakeProps } from 'types/modals';
import { usePostShowHandshakeModalMutation } from 'app/services/rolebot';
import { usePatchTalentMutation } from 'app/services/rolebot';
import { toastError } from 'utils/toastify-messages';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setShowHandshakeModal } from 'features/app/appSlice';

const Handshake = ({ id, context, innerProps }: ContextModalProps<HandshakeProps>) => {
  const dispatch = useAppDispatch();
  const [showHandshakeModal] = usePostShowHandshakeModalMutation();
  const [patchTalent] = usePatchTalentMutation();
  const [isOutreachInProgress, setIsOutreachInProgress] = useState(innerProps.isOutreachInProgress);

  const handlePrimaryButtonClick = async () => {
    try {
      await patchTalent({
        roleId: innerProps.roleId,
        talentId: innerProps.talentId,
        payload: { is_turnkey: 1 },
      }).unwrap();

      if (innerProps.handlePatchCompleted) {
        innerProps.handlePatchCompleted('is_turnkey', innerProps.talentId, true);
      }

      setIsOutreachInProgress(true);
      context.closeModal(id);
    } catch (error: any) {
      if (error.status === 402) {
        toastError('Payment required: Unable to start outreach due to insufficient tokens');
      } else {
        toastError('Error starting outreach');
      }
    }
  };

  useEffect(() => {
    if (innerProps.setIsOutreachInProgress) {
      innerProps.setIsOutreachInProgress(isOutreachInProgress);
    }
  }, [isOutreachInProgress, innerProps]);

  const handleDontShowAgain = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = !e.target.checked;
    
    // Update in the backend
    showHandshakeModal({
      payload: {
        show_handshake_modal: newValue
      }
    });
    
    // Update in Redux store - this will affect all components
    dispatch(setShowHandshakeModal(newValue));
  }

  return (
    <>
      <TitleWithClose id={id} title={'Outreach'} />
      <Text mb={20} color={'#242424'}>
        We will charge you 5 extra tokens for each talent you want to rolebot to contact.
      </Text>
      <Group position={'right'} mt={40}>
        <RolebotButton onClick={handlePrimaryButtonClick}>
          Start Outreach
        </RolebotButton>
      </Group>
      <Checkbox label="Don't show this again" onChange={handleDontShowAgain}/>
    </>
  );
};

export default Handshake;