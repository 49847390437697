import { Group, Stack, Text, Title, Select, Card, createStyles, Loader, Center } from "@mantine/core";
import { IAvailableCreditPlan } from "types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import RolebotButton from "components/public/Buttons/RolebotButton";

interface CreditPlanSelectorProps {
  availableCreditPlans: IAvailableCreditPlan[];
  hasPaymentMethod: boolean;
  openConfirmPaymentModal: (plan: IAvailableCreditPlan) => void;
  selectedPlanId: number | null;
  handleSelectCreditPlan: (planId: number) => void;
  isLoading: boolean;
}


const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: '#F0F4F8',
    padding: '20px',
    borderRadius: '12px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  card: {
    backgroundColor: '#FFFFFF',
    padding: '15px',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  button: {
    marginTop: '20px',
  },
}));

const CreditPlanSelector = ({ 
  availableCreditPlans, 
  hasPaymentMethod, 
  openConfirmPaymentModal, 
  selectedPlanId, 
  handleSelectCreditPlan, 
  isLoading,
}: CreditPlanSelectorProps) => {
  const {classes} = useStyles();

  const handlePlanAction = () => {
    const selectedPlan = availableCreditPlans.find(plan => plan.id === selectedPlanId);
    if (selectedPlan) {
      openConfirmPaymentModal(selectedPlan);
    }
  };

  const handlePlanChange = (value: string) => {
    handleSelectCreditPlan(Number(value));
  };
  

  const selectedPlan = availableCreditPlans.find(plan => plan.id === selectedPlanId);

  return (
    <Stack className={classes.container}>
      <Title order={4} color="#242424">
        Choose your plan!
      </Title>
      <Select
        placeholder="Select a plan"
        data={availableCreditPlans.map(plan => ({ value: plan.id.toString(), label: plan.name }))}
        onChange={handlePlanChange}
      />
      {selectedPlan && (
        <Card className={classes.card} shadow="sm" radius="md" withBorder>
          <Title order={6} color="#242424">
            {selectedPlan.name}
          </Title>
          <Text size="md" color="#2D3748">
            Candidate Volume: {selectedPlan.candidate_volume} credits monthly
          </Text>
          <Text size="md" color="#2D3748">
            Cost Per Candidate: ${selectedPlan.cost_per_candidate / 100} per candidate
          </Text>
          <Text size="md" color="#2D3748">
            Billing type: {selectedPlan.billing_type}
          </Text>
          <Group>
            {isLoading ? <Center style={{ width: '100%' }}><Loader /></Center> : (
              <RolebotButton
                variant="filled"
                size="sm"
                disabled={!hasPaymentMethod}
                onClick={handlePlanAction}
                leftIcon={<FontAwesomeIcon icon={faCheck} size="lg" />}
              className={classes.button}
            >
                {hasPaymentMethod ? 'Accept this plan now' : 'Enter payment method'}
              </RolebotButton>
            )}
          </Group>         

        </Card>
      )}
    </Stack>
  );
};

export default CreditPlanSelector;


