import { createStyles, Group, Stack, Text, Title } from "@mantine/core";
interface CurrentCreditPlanProps {
  candidateVolume: number | null;
  costPerCandidateInDollars: number | null;
  billingRenewalDate: string;
  planName: string;
}


const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: '#F0F4F8',
    padding: '20px',
    borderRadius: '12px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  card: {
    backgroundColor: '#FFFFFF',
    padding: '15px',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
}));

const CurrentCreditPlan = ({ candidateVolume, costPerCandidateInDollars, billingRenewalDate, planName }: CurrentCreditPlanProps) => {
  const {classes} = useStyles();
  return (
    <Stack spacing={10} className={classes.container}>
        <Title order={4} color="#242424">
          Current Plan
        </Title>
        <Stack spacing={5} mt={20} className={classes.card}>
          <Title order={5} color="#242424">
            {planName}
          </Title>

          <Group position="apart">
            <Text size="md" weight={500} color="#2D3748">
              ${costPerCandidateInDollars} per candidate
            </Text>
          </Group>

          <Text size="md" color="#2D3748">
            Candidate Volume: {candidateVolume} candidates
          </Text>

          <Text size="md" color="#2D3748">
            To be renewed on {billingRenewalDate}
          </Text>          
      </Stack>
    </Stack>
  );
};

export default CurrentCreditPlan;


