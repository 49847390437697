import { createStyles } from "@mantine/core";
import { ICustomer } from "types";

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  text: {
    marginRight: '10px',
  },
  title: {
    fontWeight: 'bold',
    marginRight: '10px',
  },
}));

const CreditPlanNumbers = ({ company }: { company: ICustomer }) => {
  const { classes } = useStyles();

  const creditsLeft = company.total_credits;


  return (
    <div className={classes.container}>
      <span className={classes.title}>Credits left: </span> <span className={classes.text}>{creditsLeft}</span>
    </div>
  )
}

export default CreditPlanNumbers;