import TalentActionIcon from 'components/TalentActionIcon/TalentActionIcon';
import { usePatchTalentMutation } from 'app/services/rolebot';
import { Box, createStyles } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useEffect } from 'react';
import { closeTalentMenu, enqueueTalent } from 'features/app/appSlice';
import useAppSelector from 'hooks/useAppSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles((theme, { hovered, active }: { hovered: boolean; active: boolean }) => ({
  icon: {
    width: 30,
    height: 30,
    fill: hovered || active ? '#6FBE44CC' : '#838485',
    [theme.fn.largerThan('md')]: {
      width: 25,
      height: 25,
    },
    marginBottom: '2px',
    color: hovered || active ? '#6FBE44CC' : '#838485'
  }
}));

interface ThumbsUpProps {
  roleId: number;
  talentId: number;
  active: boolean;
  size?: number;
  handleSetTalent?: any;
  handlePatchCompleted?: any;
  type?: string;
  isButtonsActive?: boolean;
  isFeatureLocked?: boolean;
}

const ThumbsUp = ({
  roleId,
  talentId,
  size = 50,
  active,
  handleSetTalent = () => {},
  handlePatchCompleted = (action: string, talentId: number | string) => {},
  isButtonsActive = true,
  isFeatureLocked,
}: ThumbsUpProps) => {
  const { hovered, ref } = useHover<HTMLDivElement>();
  const { classes } = useStyles({ hovered, active });
  const talentMenuOpen = useAppSelector((state) => state.app.talentMenuOpen);
  const dispatch = useAppDispatch();
  const [patchTalent, { isSuccess, isLoading }] = usePatchTalentMutation();

  useEffect(() => {
    if (!isSuccess) return;
    if (talentMenuOpen) {
      dispatch(closeTalentMenu());
      dispatch(enqueueTalent({ count: 1 }));
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const handleAcceptTalent = async () => {
    //if button is already active, return early since we don't need to make the request again.
    if (active) return;
    try {
      handleSetTalent(talentId);

      await patchTalent({
        roleId: roleId,
        talentId: talentId,
        payload: {
          accepted: 1,
        },
      }).unwrap()
      .then((response: any) => {
        handlePatchCompleted('accepted', talentId, response.should_show_feedback_modal);
      })

    } catch (e) {
      throw e;
    }
  };

  return (
    <Box ref={ref}>
      <TalentActionIcon
        hoverColor={'#6FBE44'}
        onClick={() => isFeatureLocked ? null : handleAcceptTalent()}
        content={isFeatureLocked ? 'Reactivate your plan to unlock this feature' : 'Accept'}
        isLoading={isLoading}
        size={size}
        active={active}
        isButtonsActive={isButtonsActive}
      >
        <FontAwesomeIcon icon={faThumbsUp} className={classes.icon} />
      </TalentActionIcon>
    </Box>
  );
};

export default ThumbsUp;
