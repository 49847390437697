import React from 'react';
import { Text, Button, Stack, Group, useMantineTheme } from '@mantine/core';
import { FaCheckCircle } from 'react-icons/fa';
import { ContextModalProps } from '@mantine/modals';
import { PaymentSuccessProps } from 'types/modals';
import TitleWithClose from '../components/TitleWithClose';
import { useNavigate } from 'react-router-dom';

const PaymentSuccessModal = ({ id, context, innerProps }: ContextModalProps<PaymentSuccessProps>) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const handleCloseModal = () => {
    context.closeModal(id);
    navigate('/roles');
  }
  
  return (
    <>
      <TitleWithClose id={id} title={'Plan Created Successfully!'} />
      <Stack align="center" spacing="md">
        <FaCheckCircle size={48} color={theme.colors.green[6]} />
        <Text size="lg" weight={500} align="center">
          Congratulations! Your new plan has been created successfully.
        </Text>
        <Text size="md" align="center">
          You can now use your credits to enjoy our services.
        </Text>
        <Button onClick={handleCloseModal} variant="outline" color="green">
          Start Using Credits
        </Button>
      </Stack>
    </>
  );
};

export default PaymentSuccessModal;