import { faHandshake } from '@fortawesome/pro-light-svg-icons';
import { useEffect } from 'react';
import { usePatchTalentMutation } from 'app/services/rolebot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, createStyles } from '@mantine/core';
import TalentActionIcon from 'components/TalentActionIcon/TalentActionIcon';
import { useModals } from '@mantine/modals';
import { useState } from 'react';
import useAppSelector from 'hooks/useAppSelector';
import { toastError } from 'utils/toastify-messages';
interface HandshakeProps {  
    active: boolean;
    talentId: number;   
    roleId: number;
    isButtonsActive: boolean;
    isFeatureLocked: boolean;
    isOutreachInProgress?: boolean;
    handleStartOutreach?: (action: string, talentId: number | string, success: boolean | null) => void;
}

const Handshake = ({
  active, 
  talentId, 
  roleId, 
  isButtonsActive, 
  isFeatureLocked, 
  isOutreachInProgress, 
  handleStartOutreach
}: HandshakeProps) => {
    const useStyles = createStyles((theme, { active }: { active: boolean }) => ({
        icon: {
        width: 30,
        height: 30,
        fill: active ? '#6FBE44' : '#838485',
        stroke: active ? '#6FBE44' : '#838485',
        strokeWidth: active ? '1px' : '0',
        [theme.fn.largerThan('md')]: {
            width: 25,
            height: 25,
        },
        marginBottom: '2px',
        color: active ? '#6FBE44' : '#838485'
        }
    }));

  const modals = useModals();
  const [patchTalent, { isSuccess, isLoading }] = usePatchTalentMutation();
  const [isOutreachInProgressState, setIsOutreachInProgressState] = useState(isOutreachInProgress);
  
  // Get the showHandshakeModal value from Redux store
  const showHandshakeModal = useAppSelector((state: any) => state.app.showHandshakeModal);

  const handleStartOutreachButtonClick = () => {

    if (isFeatureLocked) {
        toastError('Reactivate your plan to unlock this feature');
        return;
    }

    if (isOutreachInProgress) {
        return;
    }

    if (showHandshakeModal) {
      modals.openContextModal('handshake', {
        innerProps: {
          talentId,
          roleId,
          isOutreachInProgress: isOutreachInProgress,
          handlePatchCompleted: handleStartOutreach,
          setIsOutreachInProgress: setIsOutreachInProgressState
        }
      });
    } else {
      patchTalent({
        roleId,
        talentId,
        payload: { is_turnkey: 1 },
      })
      .unwrap()
      .then(() => {
        setIsOutreachInProgressState(true);

        if (handleStartOutreach) {
          handleStartOutreach('is_turnkey', talentId, true);
        }
      }).catch((error) => {
        // Check if the response code is 402 (Payment Required)
        if (error.status === 402) {
          toastError('Payment required: Unable to start outreach due to insufficient tokens');
        } else {
          toastError('Error starting outreach');
        }
        
        // Set outreach status to false since it failed
        setIsOutreachInProgressState(false);
        
        if (handleStartOutreach) {
          handleStartOutreach('is_turnkey', talentId, false);
        }
      })
    }
  }
  const { classes } = useStyles({active});

  return (
    <Box>
      <TalentActionIcon
        hoverColor={'#6FBE44'}
        content={'Start Outreach'}
        size={50}
        active={false}
        isButtonsActive={true}
        isLoading={false}
        onClick={handleStartOutreachButtonClick}
      >
        <FontAwesomeIcon icon={faHandshake} className={classes.icon} />
      </TalentActionIcon>
    </Box>
  );
};

export default Handshake;
