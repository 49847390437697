// This is the new default sign up page for the user

import { useEffect, useState } from 'react';
import { Alert, Button, Container, createStyles, Group, Image, Stack, Text, TextInput, Title } from '@mantine/core';
import LogoLetters from 'assets/img/rolebot-wordmark.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useSignUpUserMutation } from 'app/services/rolebot';
import { mapErrors } from 'utils';

const useStyles = createStyles((theme) => ({
  image: {
    width: 160,
    marginTop: 40,
    [theme.fn.largerThan('md')]: {
      width: 180,
      marginTop: 50,
    },
  },
  title: {
    marginTop: 30,
    [theme.fn.largerThan('md')]: {
      marginTop: 50,
    },
  },
  member: {
    marginTop: 24,
    [theme.fn.largerThan('md')]: {
      marginInline: 44,
    },
  },
  policy: {
    marginBlock: 14,
    [theme.fn.largerThan('md')]: {
      marginInline: 24,
    },
  },
}));

const SignUpFormSchema = z
  .object({
    name: z.string().min(3, 'Must be at least 3 characters long').nonempty('Name is required'),
    password: z
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .refine((v) => v.match(/[A-Z]/), 'Password must contain at least one uppercase letter')
      .refine((v) => v.match(/[a-z]/), 'Password must contain at least one lowercase letter'),
    confirm: z.string(),
    company_name: z.string().min(3, 'Must be at least 3 characters long'),
    email: z.string().email('Invalid email address'),
  })
  .refine((data) => data.password === data.confirm, {
    message: 'Passwords must match',
    path: ['confirm'],
  });

type SignUpFormPayload = z.infer<typeof SignUpFormSchema>;

const   SignUpUser = () => {
  const { classes } = useStyles();
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      password: '',
      confirm: '',
      company_name: '',
      email: '',
    },
    resolver: zodResolver(SignUpFormSchema),
  });

  // change this for the mutation to create an account
  const [signUpUser, { isSuccess, isError, isLoading, error }] = useSignUpUserMutation();

  const onSubmit = (data: SignUpFormPayload) => {
    signUpUser({
      name: data.name,
      password: data.password,
      password_confirmation: data.confirm,
      company_name: data.company_name,
      email: data.email,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate('/');
    }
  }, [navigate, isSuccess]);

  return (
    <Container size={600} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Link to={'/'}>
        <Image src={LogoLetters} fit={'contain'} className={classes.image} />
      </Link>
      <Title order={3} mb={20} className={classes.title}>
        Create Account
      </Title>
      <Stack>
        <form onSubmit={handleSubmit(onSubmit)} id="sign-in-form">
          <Stack>
            <TextInput
              label={'Name'}
              size={'md'}
              placeholder={'Bob Ross'}
              {...register('name')}
              error={errors.name?.message}
            />
            <TextInput
              label={'Company Name'}
              size={'md'}
              placeholder={'ACME'}
              {...register('company_name')}
              error={errors.company_name?.message}
            />

            <TextInput
              label={'Email'}
              placeholder={'Email'}
              {...register('email')}
              size={'md'}
              error={errors.email?.message}
            />

            <TextInput
              label={'Password'}
              placeholder={'8+ Characters'}
              {...register('password')}
              size={'md'}
              error={errors.password?.message}
              rightSectionProps={{ style: { marginRight: 15 } }}
              type={show ? 'password' : 'text'}
              rightSection={
                <Text
                  size={'sm'}
                  color={'#838485'}
                  onClick={() => setShow(!show)}
                  sx={{ cursor: 'pointer', userSelect: 'none' }}
                >
                  {show ? 'SHOW' : 'HIDE'}
                </Text>
              }
            />
            <TextInput
              label={'Confirm New Password'}
              placeholder={'Confirm New Password'}
              {...register('confirm')}
              size={'md'}
              error={errors.confirm?.message}
              type={'password'}
            />
            <Group spacing={4} position={'left'} sx={{ color: '#838485' }} className={classes.policy}>
              <Text>By signing up you agree to our </Text>
              <Text component={'a'} href={'https://www.rolebot.io/terms-of-service'} target={'_blank'} color={'blue'}>
                Terms of Service
              </Text>
              and
              <Text component={'a'} href={'https://www.rolebot.io/privacy-policy'} target={'_blank'} color={'blue'}>
                Privacy Policy
              </Text>
              .
            </Group>
            <Button
              type="submit"
              ml={'auto'}
              px={40}
              size={'md'}
              loading={isLoading}
              loaderPosition={'right'}
              disabled={!isValid || isLoading}
            >
              Sign Up
            </Button>
          </Stack>
        </form>

        {isError && (
          <Alert color={'red'}>
            {mapErrors(error)[0] || 'Oops something went wrong. Please contact support if this keeps happening.'}
          </Alert>
        )}
        <Group align={'center'} position={'center'} spacing={4} className={classes.member}>
          <Text>Have an account?</Text>
          <Text component={Link} to={'/'} color={'#7039ED'}>
            Sign in
          </Text>
        </Group>
      </Stack>
    </Container>
  );
};
export default SignUpUser;
