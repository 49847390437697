import React from 'react';
import { Group, Text, Image, Stack } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons';
import { Circle } from 'tabler-icons-react';
import RolebotButton from 'components/public/Buttons/RolebotButton';

interface PaymentMethodProps {
  stripeId: string | null;
  cardLogo: string;
  getBillingDisplayNumbers: () => string;
  openPaymentMethodModal: () => void;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  stripeId,
  cardLogo,
  getBillingDisplayNumbers,
  openPaymentMethodModal,
}) => {
  return (
    <>
    <Stack spacing={10} sx={{ backgroundColor: '#F8F9FA', padding: '20px', borderRadius: '8px' }}>
      {Boolean(stripeId) && (
          <Group mt={30}>
            <Text color={'#242424'}>Credit Card</Text>
          {cardLogo === '' && <FontAwesomeIcon icon={faCreditCard} width={17} height={17} color="#242424" />}
          {cardLogo !== '' && <Image src={cardLogo} style={{ width: '32px', height: '22px' }} />}
          <Group noWrap spacing={10}>
            <Group spacing={7} noWrap>
              <Circle size={6} color={'#4F4F4F'} fill={'#4F4F4F'} />
              <Circle size={6} color={'#4F4F4F'} fill={'#4F4F4F'} />
              <Circle size={6} color={'#4F4F4F'} fill={'#4F4F4F'} />
              <Circle size={6} color={'#4F4F4F'} fill={'#4F4F4F'} />
            </Group>
            <Text>{getBillingDisplayNumbers()}</Text>
          </Group>
        </Group>
      )}
      <Group position={'left'} mt={30}>
        <RolebotButton type={Boolean(stripeId) ? 'neutral' : 'primary'} onClick={openPaymentMethodModal}>
          {Boolean(stripeId) ? 'Change Payment Method' : 'Add Payment Method'}
        </RolebotButton>
      </Group>
    </Stack>
    </>
  );
};

export default PaymentMethod; 