import { Button, Group, Stack, Tabs, Text, Title, createStyles } from '@mantine/core';
import { useModals } from '@mantine/modals';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import VisaLogo from 'assets/img/logos/visa_logo.png';
import MastercardLogo from 'assets/img/logos/mastercard_logo.png';
import AmexLogo from 'assets/img/logos/amex_logo.png';
import UnionpayLogo from 'assets/img/logos/unionpay_logo.png';
import DiscoverLogo from 'assets/img/logos/discover_logo.png';
import DinersLogo from 'assets/img/logos/diners_logo.png';
import JcbLogo from 'assets/img/logos/jcb_logo.png';
import { useEffect, useMemo, useReducer, useState } from 'react';
import moment from 'moment';
import useOpenModal from 'hooks/useOpenModal';
import { getReactivatePlanToastContent, toastError, toastSuccess } from 'utils/toastify-messages';
import { usePostReactivatePlanMutation, useGetAvailableCreditPlansQuery, useGetCompanyQuery, usePatchCustomerCreditPricingOverageMutation, usePostCustomerCreditPricingPlanMutation, useCreateStripeSubscriptionMutation, useGetCustomerPlansHistoryQuery } from 'app/services/rolebot';
import { useLocation } from 'react-router-dom';
import { IAvailableCreditPlan, ICustomer } from 'types';
import PaymentMethod from './PaymentMethod';
import CreditBalance from './CreditBalance';
// import CreditOverages from './CreditOverages';
import CurrentCreditPlan from './CurrentCreditPlan';
import CreditPlanSelector from './CreditPlanSelector';
import { useActivateCreditPlanMutation } from 'app/services/rolebot';
import PlanHistory from 'components/PlanHistory/PlanHistory';

const useStyles = createStyles((theme) => ({
  cardLogo: {
    width: '32px',
    height: '22px',
  },
  planDetails: {
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
    padding: '30px',
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  planDetailsGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const initialState = {
  auto_renew: true,
  enable_overages: true,
  maximum_overage_credit: 100,
  overage_updated: false,
  overageSubmitDisabled: true,
  selectedCreditPlanId: null
};

type StateType = {
  auto_renew: boolean;
  enable_overages: boolean;
  maximum_overage_credit: number;
  overage_updated: boolean;
  overageSubmitDisabled: boolean;
  selectedCreditPlanId: number | null;
};

type ActionType =
  | { type: 'SET_OVERAGE_OPTION'; payload: string }
  | { type: 'SET_BALANCE'; payload: number }
  | { type: 'CUSTOMER_DATA_RETURNED'; payload: ICustomer }
  | { type: 'ON_MAX_OVERAGE_CHANGE'; payload: number }
  | { type: 'ON_OVERAGE_OPTION_CHANGE'; payload: string }
  | { type: 'ON_PLAN_SELECTION_CHANGE'; payload: number | null }


  function reducer(state: StateType, action: ActionType) {
    switch (action.type) {
      case 'CUSTOMER_DATA_RETURNED':
        return {
          ...state,
          enable_overages: action.payload.customerCreditPlan?.enable_overages,
          maximum_overage_credit: action.payload.customerCreditPlan?.maximum_overage_credit,
        };
      case 'ON_MAX_OVERAGE_CHANGE':
        return {
          ...state,
          maximum_overage_credit: action.payload,
          overageSubmitDisabled: false
        };
      case 'ON_OVERAGE_OPTION_CHANGE':
        return {
          ...state,
          enable_overages: action.payload === 'enable',
          overageSubmitDisabled: false
        };
      case 'ON_PLAN_SELECTION_CHANGE':
        return {
          ...state,
          selectedCreditPlanId: action.payload
        };
      default:
        return state;
    }
  }

const Billing = () => {
  const { classes } = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data, refetch: refetchCompany } = useGetCompanyQuery() as { data: ICustomer, refetch: () => void };
  const { data: availableCreditPlans = [], refetch: refetchAvailableCreditPlans } = useGetAvailableCreditPlansQuery({ customerId: data.id });
  
  const availableCreditPlansArray = useMemo(() => {
    if (!data.customerCreditPlan || !data.customerCreditPlan.credit_plan_info) return [];
    return Object.values(availableCreditPlans).filter((plan) => plan.id !== data.customerCreditPlan.credit_plan_info.id);
  }, [availableCreditPlans, data.customerCreditPlan]);

  const [patchCustomerCreditPricingOverage, { isLoading: isPatchCustomerCreditPricingOverageLoading, isSuccess: isPatchCustomerCreditPricingOverageSuccess, isError: isPatchCustomerCreditPricingOverageError }] = usePatchCustomerCreditPricingOverageMutation();

  const [postCustomerCreditPricingPlan, { isLoading: isPostCustomerCreditPricingPlanLoading, isSuccess: isPostCustomerCreditPricingPlanSuccess, isError: isPostCustomerCreditPricingPlanError }] = usePostCustomerCreditPricingPlanMutation();
  const [createStripeSubscription, { isLoading: isCreateStripeSubscriptionLoading, isSuccess: isCreateStripeSubscriptionSuccess, isError: isCreateStripeSubscriptionError }] = useCreateStripeSubscriptionMutation();
  const [activateCreditPlan, { isLoading: isActivateCreditPlanLoading, isSuccess: isActivateCreditPlanSuccess, isError: isActivateCreditPlanError }] = useActivateCreditPlanMutation();
  const { data: customerPlansHistory = [] } = useGetCustomerPlansHistoryQuery({ customerId: data.id });
  const {
    plan_end_date,
    pm_last_four,
    pm_type,
    is_from_drive_through,
    active_role_limit,
    latest_downgrade,
    latest_upgrade,
    stripe_id,
    is_credit_pricing,
    customer_pricing_plan = {},
  } = data;
  
  const { subscription_type = '' } = customer_pricing_plan || {};

  useEffect(() => {
    if (data) {
      dispatch({ type: 'CUSTOMER_DATA_RETURNED', payload: data });
    }
  }, [data]);

  const isCreditPricing = Boolean(is_credit_pricing);
  const isFreemiumAccount = subscription_type === 'Freemium';
  const activeCreditPlan = data.customerCreditPlan || {};
  const creditPlanInfo = activeCreditPlan?.credit_plan_info || {};
  const candidateVolume = creditPlanInfo.candidate_volume || 0;
  const costPerCandidateInDollars = creditPlanInfo.cost_per_candidate / 100 || 0;
  const billingRenewalDate = moment(activeCreditPlan.billing_renewal_date).format('MMM DD, YYYY');
  const balance = data.total_credits;

  const [cardLogo, setCardLogo] = useState('');
  const [postReactivatePlan, { isLoading, isSuccess, isError, reset }] = usePostReactivatePlanMutation();
  const location = useLocation();

  const modals = useModals();
  const { contactSupport, cancelPlanRenewal, confirmPaymentModal } = useOpenModal();

  const openPaymentMethodModal = () => {
    modals.openContextModal('paymentMethod', {
      title: '',
      innerProps: {},
      closeOnClickOutside: false,
    });
  };

  const openPaymentSuccessModal = () => {
    modals.openContextModal('paymentSuccess', {
      title: '',
      innerProps: {},
      closeOnClickOutside: false,
    });
  };

  useEffect(() => {
    window.history.replaceState({}, '');
    if (data && data.customer_pricing_plan?.requires_reactivation && (location.state as any)?.reactivate) {
      modals.openContextModal('reactivationFormModal', {
        title: '',
        innerProps: { data },
        closeOnClickOutside: false,
        styles: {
          modal: { width: '602px', padding: '20px !important' },
        },
      });
    }
  }, [data]);

  const handleCancelPlanRenewalClick = () => {
    cancelPlanRenewal({
      nextBillingDate: moment.utc(plan_end_date).format('MMM DD, YYYY'),
    });
  };

  const handleSelectPlan = () => {
    window.open(process.env.REACT_APP_SHOPIFY_SELECT_PLAN_URL + '/pages/pricing', '_blank');
  };

  const handleReactivatePlan = async () => {
    await postReactivatePlan({}).unwrap();

    toastSuccess(getReactivatePlanToastContent());
  };

  const getBillingDisplayNumbers = () => {
    const lastFour = pm_last_four ? pm_last_four.toString() : '';
    return lastFour.padStart(4, '0');
  };

  const getCardLogo = (cardBrand: string | null | undefined) => {
    if (!cardBrand) return '';

    var brands = {
      visa: VisaLogo,
      mastercard: MastercardLogo,
      amex: AmexLogo,
      unionpay: UnionpayLogo,
      discover: DiscoverLogo,
      jcb: JcbLogo,
      diners: DinersLogo,
      default: '',
    };

    //as keyof typeof cards => 'visa' | 'mastercard' | 'amex' | 'unionpay' | 'discover' | 'default'
    //we use this so TS knows that cardBrand is a valid key of brands, otherwise it will throw an error
    return brands[cardBrand as keyof typeof brands] || brands['default'];
  };

  useEffect(() => {
    if (pm_type) {
      const cardLogo = getCardLogo(pm_type);
      setCardLogo(cardLogo);
    }
  }, [pm_type]);

  const handleRefetchData = () => {
    refetchCompany();
    refetchAvailableCreditPlans();
    dispatch({ type: 'CUSTOMER_DATA_RETURNED', payload: data });
  };

  // Function to handle overage option change
  const handleOverageOptionChange = (option: string) => {
    dispatch({type: 'ON_OVERAGE_OPTION_CHANGE', payload: option });
  };

  // Function to handle max overage credits change
  const handleMaxOverageCreditsChange = (credits: number) => {
    dispatch({ type: 'ON_MAX_OVERAGE_CHANGE', payload: credits });
  };
  
  const handlePatchCustomerCreditPricingOverage = async () => {
    if (isPatchCustomerCreditPricingOverageLoading) return;
    try {
      await patchCustomerCreditPricingOverage({
        customerId: data.id,
        payload: { enable_overages: state.enable_overages, maximum_overage_credit: state.maximum_overage_credit },
      }).unwrap();
      toastSuccess('Overage settings updated successfully');
    } catch (error) {
      toastError('Error updating overage settings');
      console.error('Error updating overage settings:', error);
    }
  };

  // Function to confirm credit plan
  // 3 steps:
  // 1. Post customer credit pricing plan
  // 2. Create stripe subscription
  // 3. Activate credit plan
  const handleAcceptCreditPlan = (plan: IAvailableCreditPlan) => {
    if (!plan) {
      console.error('Plan is not defined');
      return;
    }

    // Attempt to post customer credit pricing plan
    postCustomerCreditPricingPlan({
      customerId: data.id,
      payload: {
        credit_plan_id: plan.id,
        billing_type: 'monthly',
      },
    })
      .unwrap()
      .then(({id: customerCreditPlanId, credit_plan_info: {billing_type = ''}}) => {
        if(billing_type && billing_type !== 'freemium'){
          handleCreateStripeSubscription(plan.id, customerCreditPlanId)
        } else {
          toastSuccess('Your plan has been successfully updated');
          handleRefetchData()
        }
      })
      .catch((error) => {
        toastError('Error accepting credit plan');
        console.error(error);
      });
  };

  // Function to handle Stripe subscription creation
  const handleCreateStripeSubscription = (planId: number, customerCreditPlanId: number) => {
    createStripeSubscription({ payload: { credit_plan_id: planId }, customerId: String(data.id) })
      .unwrap()
      .then(({ subscription }) => {
        if (subscription && subscription.status === 'active') {
          handleActivateCreditPlan(customerCreditPlanId);
        } else {
          handlePaymentError();
        }
      })
      .catch((error) => {
        handlePaymentError();
        toastError(error.data.payment_error_message || 'Error creating subscription');
        console.error(error);
      });
  };

  // Function to handle credit plan activation
  const handleActivateCreditPlan = (customerCreditPlanId: number) => {
    activateCreditPlan({ customerId: String(data.id), payload: { customer_credit_plan_id: customerCreditPlanId } })
      .unwrap()
      .then(() => {
        openPaymentSuccessModal();
        toastSuccess('Your plan has been successfully updated');
        handleRefetchData();
      })
      .catch((error: any) => {
        toastError('Error activating your plan');
        console.error(error);
      });
  };

  const handleSelectCreditPlan = (planId: number | null) => {
    dispatch({ type: 'ON_PLAN_SELECTION_CHANGE', payload: planId });
  };
  
  const openConfirmPaymentModal = (selectedPlan: IAvailableCreditPlan) => {
    modals.openContextModal('confirmPayment', {
      title: '',
      innerProps: {
        actualPlanInfo: data.customerCreditPlan?.credit_plan_info,
        selectedPlan,
        onConfirm: () => handleAcceptCreditPlan(selectedPlan),
      },
      closeOnClickOutside: false,
    });
  };

  const handlePaymentError = () => {
    modals.openContextModal('paymentError', {
      title: '',
      innerProps: {
        openPaymentMethod: () => openPaymentMethodModal(),
      },
      closeOnClickOutside: false,
    });
  };

  return (
    <div>
      <Stack mb={30}>
        <Title order={2} style={{ color: '#242424' }}>
          Billing
        </Title>
      </Stack>

      <Tabs mb={30} variant={'unstyled'}>
        {is_from_drive_through ? (
          <Tabs.Tab label="Overview">
            <Text color={'#242424'} mt={20} mb={20} style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '22px' }}>
              Plan Details
            </Text>
            <Stack className={classes.planDetails}>
              <Group className={classes.planDetailsGroup}>
                <Stack spacing={2}>
                  <Text color={'#242424'}>Plan</Text>
                  <Text color={'#242424'} style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '22px' }}>
                    {isFreemiumAccount ? 'Freemium' : data?.customer_pricing_plan?.pricing_plan?.name}
                  </Text>
                </Stack>
                <Stack spacing={2}>
                  <Text color={'#242424'}>Role limit</Text>
                  <Text color={'#242424'} style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '22px' }}>
                    {active_role_limit}
                  </Text>
                </Stack>
                <Stack spacing={2}>
                  <Text color={'#242424'}>Billing cycle</Text>
                  <Text color={'#242424'} style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '22px' }}>
                    {isFreemiumAccount ? '-' : data?.customer_pricing_plan?.billing_cycle}
                  </Text>
                </Stack>
                <Stack spacing={2}>
                  <Text color={'#242424'}>Renewal amount</Text>
                  <Text color={'#242424'} style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '22px' }}>
                    ${parseInt(data?.customer_pricing_plan?.amount)}
                  </Text>
                </Stack>
                <Stack spacing={2}>
                  <Text color={'#242424'}>Next bill date</Text>
                  <Text color={'#242424'} style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '22px' }}>
                    {isFreemiumAccount
                      ? '-'
                      : moment.utc(data?.customer_pricing_plan?.next_billing_date).format('MMM DD, YYYY')}
                  </Text>
                </Stack>
              </Group>
              {data?.customer_pricing_plan?.cancelation_date && (
                <Group>
                  <Text color={'red'} style={{ fontFamily: 'Helvetica', fontSize: '16px' }}>
                    Plan has been successfully cancelled
                  </Text>
                </Group>
              )}
              {latest_downgrade && (
                <Group>
                  <Text color={'#4F4F4F'} style={{ fontFamily: 'Helvetica', fontSize: '16px', marginTop: 20 }}>
                    Your downgrade request will take effect on{' '}
                    <span style={{ fontWeight: 700 }}>
                      {moment.utc(latest_downgrade.scheduled_at).format('MMM DD, YYYY')}
                    </span>
                    , reducing your role limit to{' '}
                    <span style={{ fontWeight: 700 }}>{data?.latest_downgrade?.quantity}</span>. Please ensure you have
                    that number of active roles by then; otherwise, any extra roles will be closed.
                  </Text>
                </Group>
              )}
              {latest_upgrade && moment.utc(latest_upgrade.show_message_until).isAfter(moment.utc()) && (
                <Group>
                  <Text color={'#4F4F4F'} style={{ fontFamily: 'Helvetica', fontSize: '16px', marginTop: 20 }}>
                    Your upgrade request was successful and is now in effect. Your new role limit is{' '}
                    <span style={{ fontWeight: 700 }}>{latest_upgrade.quantity}</span>. Please note that your upcoming
                    billing will include the new plan amount (
                    <span style={{ fontWeight: 700 }}>
                      ${latest_upgrade.quantity * data?.latest_upgrade?.product_price}
                    </span>
                    ) plus a prorated surcharge for the remaining days until the next billing cycle.
                  </Text>
                </Group>
              )}
            </Stack>

            {data?.customer_pricing_plan?.subscription_type === 'Annual' && (
              <Stack>
                <Text color={'#4F4F4F'} mt={20} style={{ fontSize: '16px', fontWeight: 400, fontFamily: 'Helvetica' }}>
                  Your company is currently subscribed to a (1) one-year plan with Rolebot, billed monthly.
                </Text>

                <Text color={'#4F4F4F'} style={{ fontSize: '16px', fontWeight: 400, fontFamily: 'Helvetica' }}>
                  <span>
                    Subscription start date:{' '}
                    <span style={{ fontWeight: 500, fontFamily: 'Roboto' }}>
                      {moment.utc(data?.plan_start_date).format('MMM DD, YYYY')}
                    </span>{' '}
                  </span>
                  <br />
                  <span>
                    Subscription end date:{' '}
                    <span style={{ fontWeight: 500, fontFamily: 'Roboto' }}>
                      {moment.utc(data?.plan_end_date).format('MMM DD, YYYY')}
                    </span>{' '}
                  </span>
                </Text>
              </Stack>
            )}
            <Text color={'#242424'} mt={40} style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '22px' }}>
              Plan Settings & Support
            </Text>
            {isFreemiumAccount && (
              <>
                <Text color={'#242424'} mt={20} style={{ fontFamily: 'Roboto', fontWeight: 500 }}>
                  Select a plan
                </Text>
                <Text color={'#242424'} mt={8}>
                  Select a plan that fits your needs. For Enterprise options, chat with our sales team for personalized
                  assistance.
                </Text>
                <Group position={'left'} mt={20}>
                  <RolebotButton type={'neutral'} onClick={handleSelectPlan}>
                    Select your plan
                  </RolebotButton>
                </Group>
              </>
            )}
            <Text color={'#242424'} mt={40} style={{ fontFamily: 'Roboto', fontWeight: 500 }}>
              Contact Support
            </Text>
            <Text color={'#242424'} mt={8}>
              Our support team is here to help with any questions you may have!
            </Text>
            <Group position={'left'} mt={20} mb={isFreemiumAccount ? 30 : 0}>
              <RolebotButton type={'neutral'} onClick={contactSupport}>
                Contact Support
              </RolebotButton>
            </Group>
            {!isFreemiumAccount && !data?.customer_pricing_plan?.cancelation_date && (
              <>
                <Text color={'#242424'} mt={40} style={{ fontFamily: 'Roboto', fontWeight: 500 }}>
                  Cancel your subscription
                </Text>
                {data?.customer_pricing_plan?.subscription_type === 'Month to Month' && (
                  <Text color={'#242424'} mt={8}>
                    Your active subscription will remain accessible until
                    <span style={{ fontWeight: 500, fontFamily: 'Roboto' }}>
                      {' '}
                      {moment.utc(data?.plan_end_date).format('MMM DD, YYYY')}
                    </span>
                  </Text>
                )}
                {data?.customer_pricing_plan?.subscription_type === 'Annual' && (
                  <Text color={'#242424'} mt={8} style={{ maxWidth: 900 }}>
                    Your company is currently subscribed to a (1) one-year plan with Rolebot. If you choose to cancel
                    your membership during this period, your subscription will remain active and will be billed until
                    its conclusion on
                    <span style={{ fontWeight: 500, fontFamily: 'Roboto' }}>
                      {' '}
                      {moment.utc(data?.plan_end_date).format('MMM DD, YYYY')}.
                    </span>
                  </Text>
                )}
                <Group position={'left'} mt={20} mb={30}>
                  <RolebotButton type={'stand'} onClick={handleCancelPlanRenewalClick}>
                    Cancel my plan
                  </RolebotButton>
                </Group>
              </>
            )}
            {!isFreemiumAccount && data?.customer_pricing_plan?.cancelation_date && (
              <>
                <Text color={'#242424'} mt={40} style={{ fontFamily: 'Roboto', fontWeight: 500 }}>
                  Reactivate your subscription
                </Text>
                <Text color={'#242424'} mt={8}>
                  You can reactivate your subscription at any time. If you have questions or would like to explore other
                  options, feel free to reach out to our team.
                </Text>
                <Group position={'left'} mt={20} mb={30}>
                  <RolebotButton
                    type={'neutral'}
                    onClick={handleReactivatePlan}
                    disabled={moment.utc(data?.plan_end_date).isAfter(moment())}
                  >
                    Reactivate my plan
                  </RolebotButton>
                </Group>
              </>
            )}
          </Tabs.Tab>
        ) : null}


        <Tabs.Tab label="Plan Settings">
          <Stack mt={20}>
            <Title order={4} color={'#242424'}>
              Payment Method
            </Title>
          </Stack>

          <PaymentMethod
            stripeId={stripe_id}
            cardLogo={cardLogo}
            getBillingDisplayNumbers={getBillingDisplayNumbers}
            openPaymentMethodModal={openPaymentMethodModal}
          />

        { isCreditPricing &&
          <Stack mt={40}>
            <Title order={4} color={'#242424'}>
              Your plan subscription
            </Title>

            { isCreditPricing && 
            
            <CurrentCreditPlan
              candidateVolume={candidateVolume}
              costPerCandidateInDollars={costPerCandidateInDollars}
              billingRenewalDate={billingRenewalDate}
              planName={activeCreditPlan.credit_plan_info.name}
            /> }

          {availableCreditPlansArray.length > 0 && (
            <CreditPlanSelector
              hasPaymentMethod={Boolean(stripe_id)}
              openConfirmPaymentModal={openConfirmPaymentModal}
              availableCreditPlans={availableCreditPlansArray}
              selectedPlanId={state.selectedCreditPlanId}
              handleSelectCreditPlan={handleSelectCreditPlan}
              isLoading={isActivateCreditPlanLoading  || isPostCustomerCreditPricingPlanLoading || isCreateStripeSubscriptionLoading }
              />
            )}

            <Title order={4} color="#242424" mt={40}>
              Credits
            </Title>

            <CreditBalance balance={balance} />

            <PlanHistory customerPlansHistory={customerPlansHistory} />

            {/* { stripe_id &&
              <CreditOverages
                overageOption={state.enable_overages ? 'enable' : 'disable'}
                setOverageOption={handleOverageOptionChange}
                costPerCandidateInDollars={costPerCandidateInDollars}
                maxOverageCredits={state.maximum_overage_credit}
                setMaxOverageCredits={handleMaxOverageCreditsChange}
                handlePatchCustomerCreditPricingOverage={handlePatchCustomerCreditPricingOverage}
                saveButtonDisabled={isPatchCustomerCreditPricingOverageLoading || state.overageSubmitDisabled}
                />
            } */}
          </Stack>
          }
        </Tabs.Tab>
      </Tabs>
    </div>
  );
};

export default Billing;
