import React, { useMemo, useReducer } from 'react';
import { Button, createStyles, Group, Stack, Text, Transition } from '@mantine/core';
import { useGetCompanyQuery } from 'app/services/rolebot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  container: {
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 15,
  },
}));



const CreditPlanAlert = () => {

  const { classes } = useStyles();
  const { data: company } = useGetCompanyQuery();
  const [open, close] = useReducer(() => false, true);
  const navigate = useNavigate();

  if(!company || !company.customerCreditPlan || !company.customerCreditPlan.credit_plan_info) return null;

  const activePlan = company.customerCreditPlan;

  const costPerCandidate = activePlan.credit_plan_info.cost_per_candidate / 100;

  const isCreditPlanExceeded = true;

  const handleUpgradePlan = () => {
    navigate('/settings/billing');
  }

  return (
<Transition transition={'slide-down'} mounted={isCreditPlanExceeded && open} >
    {(styles) => (
      <div
        style={{
          ...styles,
          backgroundColor: '#F4DCFF',
          padding: '20px 0',
          position: 'relative',
        }}
      >
        <Stack sx={{ width: '100%', alignItems: 'center' }}>
          <Text align={'center'} color={'#242424'} className={classes.text} mx={'auto'}>
            You've reached your monthly limit. Upgrade your plan now to get additional credit.
            {/* You've reached your monthly limit. For additional credit, you will be charged ${costPerCandidate} per candidate until the next monthly cycle. */}
          </Text>
          <Button variant="outline" onClick={handleUpgradePlan}>
            Upgrade Plan
          </Button>
        </Stack>
        <FontAwesomeIcon
          icon={faXmark}
          style={{ 
            position: 'absolute',
            right: '20px',
            top: '20px',
            cursor: 'pointer'
          }}
          onClick={close}
          color={'#242424'}
          width={20}
        />
      </div>
    )}
  </Transition>
  );
};

export default CreditPlanAlert;
