import { ICustomerPlanWithPlanInfo } from "types";
import { Title, Table, Badge } from "@mantine/core";

const PlanHistory = ({ customerPlansHistory }: { customerPlansHistory: ICustomerPlanWithPlanInfo[] }) => {

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    });
  };


  return (
    <div>
      <Title order={4} color="#242424" mt={40}>
        Plan History
      </Title>
      <Table mt="md" striped highlightOnHover>
        <thead>
          <tr>
            <th>Plan Name</th>
            <th>Status</th>
            <th>Credits</th>
            <th>Renewal Date</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {customerPlansHistory.map((plan) => (
            <tr key={plan.id}>
              <td>{plan.credit_plan_info.name}</td>
              <td>
              <Badge variant="light">
                  {plan.status}
                </Badge>
              </td>
              <td>{plan.credits}</td>
              <td>{formatDate(plan.billing_renewal_date)}</td>

              <td>{formatDate(plan.created_at)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default PlanHistory;
