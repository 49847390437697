import { createStyles, Stack, Text } from '@mantine/core';

interface CreditBalanceProps {
  balance: number | null;
}

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: '#F0F4F8',
    padding: '20px',
    borderRadius: '12px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
}));

const CreditBalance = ({ balance }: CreditBalanceProps) => {
  const { classes } = useStyles();
  return (
    <Stack
      spacing={10}
      className={classes.container}
    >
      <Text size="md" weight={500} color="#242424">
        Credit Balance
      </Text>
      <Stack spacing={5}>
        <Text size="xl" weight={500} color="#242424">
          {balance} credits left
        </Text>
        <Text size="sm" color="#868e96">
        Your credit balance includes credits from your active plan and non-expired canceled plans. <br /> 
        </Text>
        <Text size="xs" color="#868e96">Note: Downgrading to Freemium removes all unused credits. </Text>
      </Stack>
    </Stack>
  );
};

export default CreditBalance;
