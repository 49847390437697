import { ContextModalProps } from '@mantine/modals';
import {Text } from '@mantine/core';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { NewCreditPlanProps } from 'types/modals';
import { useNavigate } from 'react-router-dom';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const NewCreditPlan = ({ id, context, innerProps }: ContextModalProps<NewCreditPlanProps>) => {
    const navigate = useNavigate();

    const handleSetUpNewPlan = () => {
        navigate('/settings/billing');
        context.closeModal(id);
    }

    return (
        <>
            <TitleWithClose id={id} title={'New Credit Plan'} />
            <Text mb={20} color={'#242424'}>
                You have been selected to participate in a new credit pricing plan. Click the button below to see and set up the new plan.
            </Text>
            <RolebotButton type={'primary'} onClick={handleSetUpNewPlan}>
                Set up new plan
            </RolebotButton>
        </>
    );
};

export default NewCreditPlan;
