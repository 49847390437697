import { ContextModalProps } from '@mantine/modals';
import {Text, Title, Stack } from '@mantine/core';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { ConfirmPaymentProps } from 'types/modals';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const ConfirmPayment = ({ id, context, innerProps }: ContextModalProps<ConfirmPaymentProps>) => {

    const { actualPlanInfo, selectedPlan, onConfirm } = innerProps;

    const formattedPlanCost = (selectedPlan.periodic_payment_cost / 100).toLocaleString("en-US");

    const formattedCostPerCredit = (selectedPlan.cost_per_candidate / 100).toLocaleString("en-US");

    const handleConfirmPayment = () => {
        onConfirm();
        context.closeModal(id);
    }

    return (
        <>
            <TitleWithClose id={id} title={'Confirm Payment'} />
            <Stack spacing="md" p="lg" style={{ backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
                <Title order={4} color="#242424" style={{ marginBottom: '10px' }}>
                    New Subscription Plan Update <Text color={'#007BFF'} size={'lg'} >{selectedPlan.name}</Text>
                </Title>

                <Text mb={20} color={'#242424'} style={{ fontSize: '16px', lineHeight: '1.5' }}>
                    Your actual plan <Text component={'span'} weight={'bold'} size={'sm'} color={'grey'}>"{actualPlanInfo.name}"</Text> will be cancelled and replaced with the new plan.
                </Text>

                <Text style={{ fontSize: '16px', lineHeight: '1.5' }}>
                    <strong>Your new plan details:</strong>
                    <br />
                    Billing type: <span style={{ color: '#007BFF' }}>{selectedPlan.billing_type}</span>
                    <br />
                    Cost per credit: <span style={{ color: '#007BFF' }}>${formattedCostPerCredit}</span>
                    <br />
                    Candidate volume: <span style={{ color: '#007BFF' }}>{selectedPlan.candidate_volume}</span>
                </Text>

                <Text mb={20} color={'#242424'} style={{ fontSize: '16px', lineHeight: '1.5' }}>
                    You will be charged <span style={{ color: '#FF5733', fontWeight: 'bold' }}>${formattedPlanCost}</span> {selectedPlan.billing_type} for the new plan.
                </Text>

                <RolebotButton type={'primary'} onClick={handleConfirmPayment} style={{ width: '100%', padding: '10px', fontSize: '16px' }}>
                    Confirm Payment
                </RolebotButton>
            </Stack>
        </>
    );
};

export default ConfirmPayment;
